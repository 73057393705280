<template>
  <div
    class="relative paged-list overflow-hidden -mx-4 px-4 md:overflow-visible md:mx-0 md:px-0"
  >
    <div>
      <Swiper
        :modules="modules"
        :speed="500"
        :autoplay="{
          delay: 6000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }"
        :effect="'fade'"
        :fadeEffect="{ crossFade: true }"
        :autoHeight="true"
        :navigation="{
          nextEl: sliderNext,
          prevEl: sliderPrev,
        }"
        :grid="{
          rows: mobileRows,
        }"
        :pagination="{
          el: sliderPagination,
          clickable: true,
          type: 'fraction',
          hideOnClick: false,
        }"
        :slidesPerView="'auto'"
        :spaceBetween="spaceBetween"
        :initialSlide="initialSlide"
        :grabCursor="false"
        :watchOverflow="true"
        :observer="true"
        :observeParents="true"
        :mousewheel="false"
        :breakpoints="{
          640: {
            grid: {
              rows: 1,
            },
            spaceBetween: spaceBetween / 2,
          },
        }"
        @slideChange="emit('slideChange', $event)"
      >
        <slot />
      </Swiper>
    </div>
    <div
      class="swiperBottomBar flex h-[44px] opacity-50"
      :class="controlsMargin"
    >
      <div
        ref="sliderNext"
        slot="button-next"
        class="swiper-button-next select-none"
      >
        <IconForward />
      </div>
      <div
        ref="sliderPagination"
        class="swiper-pagination leading-[44px] text-sm font-sans"
      />
      <div
        ref="sliderPrev"
        slot="button-prev"
        class="swiper-button-prev select-none"
      >
        <IconBackward />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Swiper } from 'swiper/vue'
import {
  Navigation,
  Mousewheel,
  Autoplay,
  EffectFade,
  FreeMode,
  Grid,
  Pagination,
} from 'swiper/modules'
import 'swiper/css/bundle'

import type ExtendedSwiper from '~/typesManual/swiper'

import IconForward from '~/assets/icons/forward.svg?component'
import IconBackward from '~/assets/icons/backward.svg?component'

withDefaults(
  defineProps<{
    spaceBetween?: number
    mobileRows?: number
    initialSlide?: number
    controlsMargin?: string
  }>(),
  {
    spaceBetween: 30,
    mobileRows: 1,
    initialSlide: 0,
    controlsMargin: 'mt-2.5',
  }
)

const emit = defineEmits<{
  slideChange: [swiper: ExtendedSwiper]
}>()

const modules = [
  Navigation,
  Mousewheel,
  Autoplay,
  EffectFade,
  FreeMode,
  Grid,
  Pagination,
]

const sliderNext = ref<HTMLDivElement | null>(null)
const sliderPrev = ref<HTMLDivElement | null>(null)
const sliderPagination = ref<HTMLDivElement | null>(null)
</script>

<style lang="scss" scoped>
// Due to the external swiper code injecting html into the DOM, some of the css style is maintained here instead of tailwind.
// the css naming here is based on the classes of the swiper injects. see https://swiperjs.com/swiper-api for details
.swiperBottomBar {
  @apply mt-0;
}
.swiper-button-next {
  @apply right-0 bottom-0 top-auto z-20;
}
.swiper-button-prev {
  @apply left-0 bottom-0 top-auto z-20;
}
.swiper-pagination {
  @apply bottom-0 top-auto;
}

.swiper-button-next,
.swiper-button-prev {
  &:after {
    @apply hidden;
  }
  &.swiper-button-disabled {
    @apply opacity-0;
  }
  svg {
    width: unset;
    height: unset;
  }
}
</style>
